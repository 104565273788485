import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "データウェアハウスとして使う Amazon Redshift について",
  "date": "2019-07-01T10:34:27.000Z",
  "slug": "entry/2019/07/01/193427",
  "tags": ["medley"],
  "hero": "./2019_07_01.png",
  "heroAlt": "redshift"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。開発本部の阪本です。`}</p>
    <p>{`今回は私が社内勉強会(TechLunch)にて Amazon Redshift(以下 Redshift)についてお話した内容を紹介させていただきます。`}</p>
    <h1>{`Redshift とは`}</h1>
    <h2>{`概要`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165024.png",
      "alt": "20190701165024.png"
    }}></img>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/redshift/"
      }}>{`Redshift`}</a>{`とは AWS サービスが提供しているデータウェアハウスで、高可用/高パフォーマンス/柔軟なスケーラビリティを実現しているのが特徴です。`}</p>
    <p>{`競合としては`}<a parentName="p" {...{
        "href": "https://cloud.google.com/bigquery/?hl=ja"
      }}>{`BigQuery`}</a>{`や`}<a parentName="p" {...{
        "href": "https://hadoop.apache.org/"
      }}>{`Hadoop`}</a>{`、また同じ AWS サービスでは`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/athena/"
      }}>{`Amazon Athena`}</a>{`も同様の位置付けになると思います。`}</p>
    <h2>{`データベースとしての特徴`}</h2>
    <p>{`Redshift の特徴として、列志向型データベースという点があります。`}</p>
    <p>{`MySQL のようなリレーショナルデータベースはデータを行（レコード）単位で保持している事に対し、Redshift は列単位で保持しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165052.png",
      "alt": "20190701165052.png"
    }}></img>
    <p>{`列単位でデータを持っているため集計クエリのような特定の列に対して大量の行を精査するのが高速である反面、行を特定してのアクセスは MySQL や PostgreSQL のような行志向のデータベースに比べてのクエリに比べて遅い傾向にあります。`}</p>
    <p>{`またデータには SQL でアクセスすることができ、構文も PostgreSQL と互換性があります。
最近スキーマレスなデータベースなどが多く出てきていますが、Redshift は事前にテーブルを作成する必要のある従来型の RDBMS の形となっており、テーブル作成時は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CREATE TABLE`}</code>{`といったデータ定義言語(DDL)を使うことになります。`}</p>
    <h2>{`機能面の特徴`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165113.png",
      "alt": "20190701165113.png"
    }}></img>
    <p>{`先にも書きましたが、データアクセス時に使う SQL は`}<a parentName="p" {...{
        "href": "https://www.postgresql.org/"
      }}>{`PostgreSQL`}</a>{`の構文と互換性があります。`}</p>
    <p>{`よって PostgreSQL 用ドライバ(JDBC 含む)さえ使えれば、後は特別に意識することなく Redshift に対して接続やクエリ発行が行えるということになります。`}</p>
    <p>{`この恩恵はプログラムだけではなく他社が展開しているサービスにも受けることができ、BI ツールの`}<a parentName="p" {...{
        "href": "https://www.tableau.com/ja-jp"
      }}>{`Tableau`}</a>{`や`}<a parentName="p" {...{
        "href": "https://redash.io/"
      }}>{`Redash`}</a>{`などもそのままデータソースとして利用することが出来ます。`}</p>
    <p>{`次に、一般的な RDBMS との差についてです。
RDBMS にはあり Redshift には無いものとしては`}</p>
    <ul>
      <li parentName="ul">{`UNIQUE 制約`}</li>
      <li parentName="ul">{`外部キー制約`}</li>
      <li parentName="ul">{`インデックスが無い`}</li>
    </ul>
    <p>{`などがあります。`}</p>
    <p>{`インデックスに関しては遠い意味での代用品(Sort Key)があるものの、基本的には使うことが出来ません。
注意点としてクエリ自体は PostgreSQL 互換なのでこれらを作る DDL 構文を受け入れてくれるますが、Redshift では無視されますのでご注意ください。`}</p>
    <p>{`逆に、Redshift 固有のものとしては`}</p>
    <ul>
      <li parentName="ul">{`Sort Key`}</li>
      <li parentName="ul">{`分散キー`}</li>
      <li parentName="ul">{`列圧縮`}</li>
    </ul>
    <p>{`などがあります。
これらについては以下で少し掘り下げて説明します。`}</p>
    <h3>{`Sort Key`}</h3>
    <p>{`テーブルをソートする際に使うインデックスのようなものです。
列単位で指定することができ、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ORDER BY`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GROUP BY`}</code>{`句などの精査速度に影響します。`}</p>
    <h3>{`分散キー`}</h3>
    <p>{`MySQL でいうパーティショニングキーに近いものとなります。`}</p>
    <p>{`Redshift のデータ分散方法は`}</p>
    <ul>
      <li parentName="ul">{`均等に分散`}</li>
      <li parentName="ul">{`キー値による分散`}</li>
      <li parentName="ul">{`全コピー`}</li>
      <li parentName="ul">{`Auto（負荷状況による自動選択)`}</li>
    </ul>
    <p>{`の 4 つで、データ量や特性によって使い分けることが出来ます。`}</p>
    <p>{`分散キーは Redshift を上記の方法でクラスタリングした際に、どのノードにどのデータを保持するかを決定する判断材料となるキーです。`}</p>
    <h2>{`運用面の特徴`}</h2>
    <h3>{`AWS コンソール`}</h3>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165138.png",
      "alt": "20190701165138.png"
    }}></img>
    <p>{`Redshift は AWS コンソール上からも詳細な情報を確認することが出来ます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165201.png",
      "alt": "20190701165201.png"
    }}></img>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/rds/"
      }}>{`Amazon RDS`}</a>{`にあるような一般的なメトリクスに加えてクエリ単位での実行状況や実行計画、そしてクエリの強制停止もコンソールから実行することができます。`}</p>
    <h3>{`データ取り込み`}</h3>
    <p>{`Redshift はインポート元となるデータ取り込み選択肢が豊富ということも特徴の一つです。`}</p>
    <h4>{`取り込み可能な形式`}</h4>
    <blockquote>
      <p parentName="blockquote">{`CSV/JSON/AVRO/PARQUET/ORC + これらの形式を圧縮したもの(BZIP,GZIP など)`}</p>
    </blockquote>
    <h4>{`読み取り元`}</h4>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://aws.amazon.com/jp/s3/"
        }}>{`Amazon S3`}</a>{`/`}<a parentName="p" {...{
          "href": "https://aws.amazon.com/jp/emr/"
        }}>{`Amazon EMR`}</a>{`/`}<a parentName="p" {...{
          "href": "https://aws.amazon.com/jp/dynamodb/"
        }}>{`Amazon DynamoDB`}</a>{`など`}</p>
    </blockquote>
    <p>{`特にデータ配置元として S3 がサポートされているので、各種サービスが出力するログや、`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/cloudwatch/"
      }}>{`Amazon CloudWatch Logs`}</a>{`からの S3 や`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/kinesis/"
      }}>{`Amazon Kinesis Data Firehose`}</a>{`からの S3 ・・など、組み合わせ次第で可能性がとても広がります。`}</p>
    <p>{`また S3 に配置しているデータはインポートせずに外部テーブルとして直接クエリを実行する機能 Amazon Redshift Spectrum があります。データ量がとても多い場合などにはこちらを利用するのも有効な手段です。`}</p>
    <h3>{`ワークロード管理`}</h3>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165215.png",
      "alt": "20190701165215.png"
    }}></img>
    <p>{`負荷に関する運用については WLM(Work Load Management)という機能があります。`}</p>
    <p>{`これは Redshift に接続するユーザをグループ単位で負荷制御を行うことが出来る機能です。`}</p>
    <p>{`制御できる項目としては`}</p>
    <h4>{`並列クエリ実行数`}</h4>
    <p>{`同一グループ内でのクエリ同時実行数。上限に達すると待ち行列に入って詰まる。`}</p>
    <h4>{`クエリ実行時間`}</h4>
    <p>{`クエリ実行時間の上限。`}</p>
    <h4>{`メモリ使用量`}</h4>
    <p>{`クエリ実行時に使うメモリ使用量の上限。`}</p>
    <p>{`などがあります。`}</p>
    <h3>{`可用性について`}</h3>
    <p>{`Redshift はクラスタリングをサポートしており、クラスタにはクエリの待受を行うリーダー(Leader)ノードと実処理を行うコンピューティングノードが作成されます。`}</p>
    <p>{`AWS も推奨しているように、Redshift はマルチノード運用を基本としています。`}</p>
    <p>{`これは Redshift の個々のデータノードは RAID5 のような形で各ノードに分散しているため、ノード障害が発生した場合でも生存ノードからノード復旧を自動で行ってくれます。
（同時に障害が発生しても復旧可能なノード数については、クラスタ内のノード数に依存します）`}</p>
    <h2>{`将来性について`}</h2>
    <p>{`過去１年半の間でもこれだけの新機能が追加されており、まだまだ進化しています。`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`UNLOAD`}</code>{`コマンドの CSV 対応`}</li>
      <li parentName="ul">{`Concurrency Scaling`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ALTER`}</code>{`文で`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`VARCHAR`}</code>{`の桁数変更`}</li>
      <li parentName="ul">{`Elastic Resize`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`UNLOAD`}</code>{`コマンドのヘッダ行出力対応`}</li>
      <li parentName="ul">{`コンソールにてクエリ実行環境追加`}</li>
      <li parentName="ul">{`ネスト化されたデータのサポート`}</li>
      <li parentName="ul">{`自動バージョンアップ方式の設定／予告確認可能`}</li>
      <li parentName="ul">{`Parquet、ORC からの IMPORT サポート`}</li>
      <li parentName="ul">{`Amazon Redshift Spectrum 東京サポート`}</li>
      <li parentName="ul">{`新ノードタイプ DC2`}</li>
      <li parentName="ul">{`Query Editor の追加`}</li>
      <li parentName="ul">{`PL/SQL プロシージャのサポート`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Vacum`}</code>{`コマンドの自動化 `}<em parentName="li">{`New!!(2018/12 リリース)`}</em></li>
      <li parentName="ul">{`WLM ワークロード管理の自動化 `}<em parentName="li">{`New!!(2019/06 リリース)`}</em></li>
    </ul>
    <h1>{`実際の使い勝手`}</h1>
    <p>{`では、実際のところ Redshift の使い勝手がどういったものなのかを実例を含めて紹介します。
ここでは dc2.large ノード数 2 のサンプル環境を使用します。`}</p>
    <h2>{`データのロード`}</h2>
    <p>{`クエリを発行するにも、まずは元になるデータが必要です。`}</p>
    <p>{`ここでは`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/Wikipedia:%E3%83%87%E3%83%BC%E3%82%BF%E3%83%99%E3%83%BC%E3%82%B9%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89"
      }}>{`日本語 Wikipedia の目次ダンプデータ`}</a>{`を 100 セット分用意し、その内容を Redshift にロードしてみます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165252.png",
      "alt": "20190701165252.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165257.png",
      "alt": "20190701165257.png"
    }}></img>
    <p>{`まずは目次データをこちらの画像の様に加工し、100 セット分のファイルとして分割し S3 へとアップロードします。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165317.png",
      "alt": "20190701165317.png"
    }}></img>
    <p>{`今回のロードするデータ量は`}<strong parentName="p">{`235,732,000 レコードの 11.9GB`}</strong>{`となりました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165331.png",
      "alt": "20190701165331.png"
    }}></img>
    <p>{`S3 にファイルが配置出来たら、それを格納するテーブルを Redshift に作ります。
この際 PostgreSQL の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CREATE TABLE`}</code>{`によってテーブルを作成します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165355.png",
      "alt": "20190701165355.png"
    }}></img>
    <p>{`テーブルの作成が完了したら、次はデータのロードです。
これも SQL クエリの`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`COPY`}</code>{`コマンドによって取り込みが行われます。
今回このロード処理は`}<strong parentName="p">{`8 分 55 秒`}</strong>{`で完了しました。`}</p>
    <p>{`データ量から考えるとかなり早いと感じますが、これはロード処理において並列処理の恩恵を最大限に受けているということが理由と考えられます。`}</p>
    <p>{`Redshift のロード処理は分割されたファイルを使って並列処理を実行するため、巨大な単一ファイルを取り込むより短時間で取り込むことができます。`}</p>
    <h2>{`クエリ発行`}</h2>
    <p>{`次に、クエリ発行についてですが、これはそのまま PostgreSQL のクエリを実行することになります。`}</p>
    <p>{`今回は先のステップで取り込んだ目次ページをタイトルごとに`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DISTINCT`}</code>{`する集計クエリを発行してみます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190701/20190701165409.png",
      "alt": "20190701165409.png"
    }}></img>
    <p>{`すると`}<strong parentName="p">{`49 秒`}</strong>{`で結果が帰ってきました。
最低限のスペックで 235,732,000 レコードを精査するクエリの実行時間としては良いスコアではないでしょうか。`}</p>
    <h1>{`不便に感じたこと`}</h1>
    <p>{`ここでは私が Redshift を運用していて不便に感じた事をいくつか紹介します。`}</p>
    <h2>{`料金が高い`}</h2>
    <p>{`これだけの機能とスペックが含まれているので仕方が無いかもしれませんが、AWS の他のサービスと比較して高価な印象があります。`}</p>
    <p>{`さらにマルチノードとなると料金が掛け算で増えることになり、スペックの選択肢が他のサービスと比べても少ないため運用の際にはよく見積もりされることをおすすめします。`}</p>
    <h2>{`更新クエリが遅い`}</h2>
    <p>{`列志向型のせいなのかランダムアクセスが苦手で、特定の行を探して更新する`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATE`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DELETE`}</code>{`は遅いです。`}</p>
    <p>{`そもそも Redshift は頻繁に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATE`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DELETE`}</code>{`する用途には向いておらず（後述）、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`INSERT`}</code>{`のみの積み上げ型や全レコード洗い替えが基本の用途になります。`}</p>
    <p>{`また、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATE`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DELETE`}</code>{`を繰り返すとパフォーマンスが低下します。`}</p>
    <p>{`これは内部的に保持している SortKey の状態が更新するたびに劣化し、連動してパフォーマンスが低下するためです。`}</p>
    <p>{`解消するためには SortKey の再構築（`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VACUM`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OPTIMIZE`}</code>{`コマンド)により回復しますが、そもそもコマンド実行時間が長く、負荷も大きいので実行タイミングは検討が必要となります。`}</p>
    <p>{`(追記)`}<strong parentName="p">{`2018/12 の`}<a parentName="strong" {...{
          "href": "https://aws.amazon.com/jp/about-aws/whats-new/2018/12/amazon-redshift-automatic-vacuum/"
        }}>{`アップデート`}</a>{`で自動実行機能が追加されました！`}</strong></p>
    <h2>{`AWS コンソールが機能しないことがある`}</h2>
    <p>{`先に多くの便利な機能を紹介しましたが、なぜかこれらが AWS コンソール上で機能してくれないことが割とあります。`}</p>
    <p>{`WLM の設定次第なのか不明ですが、実行中のクエリが出なかったりクエリの強制停止が効かないなど、イザという時に限って使えないことがよくありました。`}</p>
    <h2>{`メンテナンスが高頻度`}</h2>
    <p>{`新機能が続々追加されていると紹介していますが、この度にメンテナンスが発生するものとなります。`}</p>
    <p>{`タイミングは事前に設定したメンテナンスウインドウの週一の曜日/時間帯ですが、経験から 2 週間に 1 度ぐらいの頻度で発生していました。`}</p>
    <p>{`この時間帯は再起動を伴う場合もあるため、Write どころか Read すら出来ない状態になることもあります。`}</p>
    <p>{`そのため日中は社内業務。夜間はバッチでといった２４時間ずっと稼働する要件を満たす事は少し厳しいものとなります。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`まとめととなりますが、Redshift は特徴をふまえると下記のような場面で利用すれば良いかなと感じています。`}</p>
    <h2>{`BI ツール等のデータソースとして`}</h2>
    <p>{`メンテ頻度や負荷の問題があるので、自分達のアプリから直接は繋げない。`}</p>
    <h2>{`履歴やマスタデータのような大量の積み上げ型データの集計`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`UPDATE`}</code>{`が発生するなら、全件入れ替えが可能なデータ。`}</p>
    <h2>{`１日の利用頻度がそれなりにあること`}</h2>
    <p>{`頻度が高く無いのであれば、Athena の方が安い。`}</p>
    <p>{`どのサービスにも言えることですが、要件の合ったサービス選びをすることが一番大事です。`}</p>
    <p>{`Redshift についても特徴がはっきりしているタイプのサービスなので、使い所を間違えないように、上手く使っていければと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      